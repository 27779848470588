
import { Injectable } from '@angular/core';

import { map, catchError } from 'rxjs/operators';

import { SharedService} from './shared.service';

import { HttpClient } from '@angular/common/http';
import { IEmail } from '../models/email';

@Injectable()
export class EmailService {

  constructor(private http: HttpClient,
   private sharedService: SharedService){}

   //apontando para WEB API + Controller
   url = this.sharedService.getMyApiUrl() + "api/Email/";

    //post - Envio basico
    enviaEmail(registro: IEmail) {
      return this.http.post(this.url, registro).pipe(
                     catchError(this.sharedService.handleErrorObservable));
    }
}
