import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-services',
    templateUrl: './services.component.html',
})
export class ServicesComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }

}