import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IEmail } from '../../custom/models/email';
import { EmailService } from '../../custom/services/email.service';

declare var require: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {

  htmlCorpoEmailContato = require("raw-loader!../../../../assets/html/email/email-contact.html");

  private email: IEmail = <IEmail>{};

  formContact: FormGroup;

  constructor(
                private objEmail: EmailService,
                private fb: FormBuilder,
            ) {

        this.formContact = fb.group({
            "nome": ["", Validators.required],
            "email": ["", Validators.required],
            "texto": ["", Validators.required],
            
          });
    }

  ngOnInit() {
    
  }

  onEnviaEmailContato(){

    this.email.Destinatario = "martin@nguobras.com.br";
    this.email.Assunto = "#ContatoSite";

    let strCorpoAux: string = this.htmlCorpoEmailContato.default;

    strCorpoAux = strCorpoAux.replace("{Nome}", this.formContact.controls["nome"].value);
    strCorpoAux = strCorpoAux.replace("{Email}", this.formContact.controls["email"].value);
    strCorpoAux = strCorpoAux.replace("{Texto}", this.formContact.controls["texto"].value);

    this.email.CorpoEmail = strCorpoAux;

    this.objEmail.enviaEmail(this.email)
          .subscribe(response => {

            alert("Obrigado por seu contato. Sua mensagem foi enviada com sucesso! Nossa equipe irá analisar e retornar em breve");

            window.location.href = "https://www.nguobras.com.br"

    }, error => alert("Erro encontrado no envio do e-mail. Tente novamente em alguns momentos. Nossa equipe tecnica está trabalhando nesta solução!"));

  }

}
