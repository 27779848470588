import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-features',
    templateUrl: './features.component.html',
    styleUrls: ['./features.component.scss']
})
export class FeaturesComponent implements OnInit {

    constructor() { }

    ngOnInit() {
    }
    
    goto(){
        //window.scrollTo(0, 3000);
        location.href = "#services";
        return false;

    }

    sectionTitle: sectionTitleContent[] = [
        {
            subTitle: 'Nossos Recursos',
            title: 'Estamos sempre buscando superar as expectativas de nossos clientes',
            paragraphText: 'Mantendo o contato próximo, nossas equipes estão sempre atentas a entender as expectativas de nossos clientes e superá-las é nosso forte.'
        }
    ]
    
    singleFeatures: singleFeaturesContent[] = [
        {
            icon: 'icofont-gears',
            link: 'consultEmp',
            title: 'Planejamento Empresarial',
            paragraphText: 'O Planejamento Estratégico é um processo gerencial de grande importância dentro das empresas de todos os portes e setores.'
        },
        {
            icon: 'icofont-edit',
            link: 'diagnostico',
            title: 'Diagnóstico de Empresa',
            paragraphText: 'Há momentos em que se torna fundamental uma visão mais realista e ampla da pequena empresa para que as ações corretivas possam ser tomadas.'
        },
        {
            icon: 'icofont-pie-chart',
            link: 'result',
            title: 'Análise de Resultados',
            paragraphText: 'Informado do rendimento da empresa, mas também para que a gestão de recursos possa ser adaptada e melhorada.'
        },
        {
            icon: 'icofont-angry',
            link: 'socialMidia',
            title: 'Planejamento de Mídias Sociais',
            paragraphText: 'Análise de mercado, região de atuação, definição de perfil ativo, plano de marketing, mídias sociais (Instagram e Facebook), métricas e monitoramento.'
        },
        {
            icon: 'icofont-pie-chart',
            link: 'impProcessos',
            title: 'Implantação dos Processos',
            paragraphText: 'Veja cada processo na sua empresa como uma engrenagem, se uma não trabalhar de forma correta afeta o todo.'
        }
        
        
    ]

}

class sectionTitleContent {
    subTitle : string;
    title : string;
    paragraphText : string;
}

class singleFeaturesContent {
    icon : string;
    title : string;
    link : string;
    paragraphText : string;
}
