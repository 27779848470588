import { Component, OnInit } from '@angular/core';
import { SharedService } from '../../custom/services/shared.service';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(private sharedService: SharedService,) { }

  ngOnInit() {
  }

  abreWhatsapp(){
    window.open("https://wa.me/" + this.sharedService.getWhatsappNumber() + "?text=Contato%20Site");
 
    

  }

}
